.profilebutton{
    border-radius: 50%;
    background-color: #58606e;
    margin: 0 10px;
    padding: 7px;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
}
.show-nav-item{
    display: flex;
}
.hide-nav-item{
    display: none;
}
.navbar{
    box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 10px -10px;
}
.navbar .nav-link{
    color: #7F7F7F !important;
    background-color: transparent;
    border: 0;
}
.navbar .nav-link.active-link{
    font-weight: bold;
    color: #000000 !important;
    background-color: transparent;
    border: 0;
    
}
.btn-bg-green-light{
    background-color: #C8D300;
    color: #fff;
}
.btn-bg-green-light:hover{
    background-color: #C8D300;
    color: #000000;
}

.show-movile{
    display: none;
}

.navbar-toggler{
    padding: 2px;
}

/* hide  on screen < 900px wide*/
@media screen and (max-width: 900px){
    .show-movile{
        display: block;
    }
    .show-nav-item{
        display: none;
    }
    .navbar-collapse .navbar-nav{
        text-align: center;
    }
    .navbar-collapse .navbar-nav .nav-item{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .hide-nav-item{
        display: block;
    }
    .profilebutton{
        margin: 0 auto;
    }
}