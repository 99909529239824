.badge.badge-e{
    color: #fff !important;
    background-color: #E20041 !important;
}
.badge.badge-h{
    color: #fff !important;
    background-color: #f7b500 !important;
}
.badge.badge-l{
    color: #fff !important;
    background-color: #43B12E !important;
}
.badge.badge-m{
    color: #fff !important;
    background-color: #919EAB !important;
}