.itemorder{
    justify-content: end;
}
span{
    margin-left: 5px;
    margin-right: 5px;
}
span:hover{
    cursor: pointer;
}
.nav-item .active{
    background-color: #e5f4e2 !important; /* e5f4e2 */
    color: #43b12e !important;
    border: 1px solid #43b12e;
    border-bottom: 1px;
    
}
.nav-link{
    color: #809fb8 !important;
}

.item-filter-show{
    display: flex;
}

.item-small-filter{
    display: none;
}

@media screen and (max-width: 768px) {
    .item-filter-show{
        display: none;
    }
    .item-small-filter{
        display: flex;
    }
    
}