@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-BoldItalic.eot');
    src: local('PFBeauSansPro-BoldItalic'),
        url('PFBeauSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-BoldItalic.woff2') format('woff2'),
        url('PFBeauSansPro-BoldItalic.woff') format('woff'),
        url('PFBeauSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-Black.eot');
    src: local('PFBeauSansPro-Black'),
        url('PFBeauSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Black.woff2') format('woff2'),
        url('PFBeauSansPro-Black.woff') format('woff'),
        url('PFBeauSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-Light.eot');
    src: local('PFBeauSansPro-Light'),
        url('PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Light.woff2') format('woff2'),
        url('PFBeauSansPro-Light.woff') format('woff'),
        url('PFBeauSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro Bbook';
    src: url('PFBeauSansPro-Bbook.eot');
    src: local('PFBeauSansPro-Bbook'),
        url('PFBeauSansPro-Bbook.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Bbook.woff2') format('woff2'),
        url('PFBeauSansPro-Bbook.woff') format('woff'),
        url('PFBeauSansPro-Bbook.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-ThinItalic.eot');
    src: local('PFBeauSansPro-ThinItalic'),
        url('PFBeauSansPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-ThinItalic.woff2') format('woff2'),
        url('PFBeauSansPro-ThinItalic.woff') format('woff'),
        url('PFBeauSansPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro XThin';
    src: url('PFBeauSansPro-XThinItalic.eot');
    src: local('PFBeauSansPro-XThinItalic'),
        url('PFBeauSansPro-XThinItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-XThinItalic.woff2') format('woff2'),
        url('PFBeauSansPro-XThinItalic.woff') format('woff'),
        url('PFBeauSansPro-XThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-Thin.eot');
    src: local('PFBeauSansPro-Thin'),
        url('PFBeauSansPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Thin.woff2') format('woff2'),
        url('PFBeauSansPro-Thin.woff') format('woff'),
        url('PFBeauSansPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro XThin';
    src: url('PFBeauSansPro-XThin.eot');
    src: local('PFBeauSansPro-XThin'),
        url('PFBeauSansPro-XThin.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-XThin.woff2') format('woff2'),
        url('PFBeauSansPro-XThin.woff') format('woff'),
        url('PFBeauSansPro-XThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-SemiBold.eot');
    src: local('PFBeauSansPro-SemiBold'),
        url('PFBeauSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-SemiBold.woff2') format('woff2'),
        url('PFBeauSansPro-SemiBold.woff') format('woff'),
        url('PFBeauSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-Bold.eot');
    src: local('PFBeauSansPro-Bold'),
        url('PFBeauSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Bold.woff2') format('woff2'),
        url('PFBeauSansPro-Bold.woff') format('woff'),
        url('PFBeauSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-SemiBoldItalic.eot');
    src: local('PFBeauSansPro-SemiBoldItalic'),
        url('PFBeauSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('PFBeauSansPro-SemiBoldItalic.woff') format('woff'),
        url('PFBeauSansPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro Bbook';
    src: url('PFBeauSansPro-BbookItalic.eot');
    src: local('PFBeauSansPro-BbookItalic'),
        url('PFBeauSansPro-BbookItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-BbookItalic.woff2') format('woff2'),
        url('PFBeauSansPro-BbookItalic.woff') format('woff'),
        url('PFBeauSansPro-BbookItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-LightItalic.eot');
    src: local('PFBeauSansPro-LightItalic'),
        url('PFBeauSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-LightItalic.woff2') format('woff2'),
        url('PFBeauSansPro-LightItalic.woff') format('woff'),
        url('PFBeauSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-Italic.eot');
    src: local('PFBeauSansPro-Italic'),
        url('PFBeauSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Italic.woff2') format('woff2'),
        url('PFBeauSansPro-Italic.woff') format('woff'),
        url('PFBeauSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-Regular.eot');
    src: local('PFBeauSansPro-Regular'),
        url('PFBeauSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-Regular.woff2') format('woff2'),
        url('PFBeauSansPro-Regular.woff') format('woff'),
        url('PFBeauSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: url('PFBeauSansPro-BlackItalic.eot');
    src: local('PFBeauSansPro-BlackItalic'),
        url('PFBeauSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('PFBeauSansPro-BlackItalic.woff2') format('woff2'),
        url('PFBeauSansPro-BlackItalic.woff') format('woff'),
        url('PFBeauSansPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

