.item-filter-show{
    display: flex;
}

.item-small-filter{
    display: none;
}

.show-devices{
    display: block;
}
.show-mobile{
    display: none;
}
@media screen and (max-width: 768px) {

    .item-filter-show{
        display: none;
    }
    .item-small-filter{
        display: flex;
    }
    
}

@media screen and (max-width: 400px) {
    .show-devices{
        display: none;
    }
    .show-mobile{
        display: block;
    }
    
}