.itembutton{
    transition: all 0.3s ease;
}
.itembutton:active{
    transform: scale(0.9);
}
.item-selected-up{
    border-top: 1px solid #43b12e;
    border-right:1px solid #43b12e;
    border-left: 1px solid #43b12e;
    transition: all 0.3s ease;
}
.item-selected-down{
    border-bottom: 1px solid #43b12e;
    border-right:1px solid #43b12e;
    border-left: 1px solid #43b12e;
    transition: all 0.3s ease;
}
.text-bg-danger{
    color: #e20041 !important;
    background-color: transparent !important;
    border: #e20041 1px solid  ;
}
.text-bg-warning{
    color: #f7b500 !important;
    background-color: transparent !important;
    border: #f7b500 1px solid  ;
}
.text-bg-success{
    color: #43b12e !important;
    background-color: transparent !important;
    border: #43b12e 1px solid  ;
}
.text-bg-secondary{
    color: #555759 !important;
    background-color: transparent !important;
    border: #555759 1px solid  ;
}
.card{
    border-radius: 14px;
}
@media screen and (max-width: 900px){
    .vermasinfo{
        display: none;
    }    
}