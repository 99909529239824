@import url('./font/stylesheet.css');
body {
  margin: 0;
  font-family: 'PF BeauSans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCFCFC;
}
.container-fluid{
  padding-left: 2em;
  padding-right: 2em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-extremo{
  color: #EA3A3D;
}

.text-bg-success{
  background-color: #43b12e !important;
}
.text-bg-high{
  color: #ffffff;
  background-color: #ff9d00 !important;
}
.text-bg-warning{
  color: #ffffff !important;
}

.text-green{
  color: #43b12e;
}

.bg-transparent{
  background-color: transparent !important;
}

.table{
  --bs-table-striped-bg: #f4f7f8;
}
.table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F4F7F8;
}

.bg-light-grey{
  background-color: #E4E7EB !important;
}
.bg-light-green{
  background-color: #F0F6F3;
}

.table thead th{
  color: #555759;
}

.table tbody td{
  color: #555759;
}

.btn-primary{
  background-color: #43b12e;
  border-color: #43b12e;
}
.btn-primary:hover{
  background-color: #73c463;
  border-color: #73c463;
}
.btn-primary:active{
  background-color: #000000 !important;
  border-color: #73c463 !important;
}
.btn-falabella{
  background-color: #c8d300 !important;
  border-color: #c8d300 !important;
  color: #fff;
}
.btn-falabella:hover{
  background-color: #e1e600 !important;
  border-color: #e1e600 !important;
  color: rgb(73, 73, 73);
}
.btn-falabella:active{
  background-color: #000000 !important;
  border-color: #e1e600 !important;
  color: #fff !important;
}

.btn{
  border-radius: 2em !important;
}

.text-dark-gray{
  color: #555759;
}

.border-right{
  border-right: 1px solid #DDE3ED;
  height: 80%;
}
.showmorebutton{
  color: #43b12e;
}
.showmorebutton:hover{
  color: #000000;
}
.form-check-input:checked{
  display: block;
  content: 'ON';
}

.page-link{
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: #000000;
  font-weight: bolder;
}

.page-link:hover{
  border: 1px solid #43b12e;
  color: #43b12e;
  background-color: #fff;
}

.rotate-180{
  transform: rotate(180deg);
}

.shadow-box{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.without-border{
  border: none;
}

.without-b-r{
  border-radius: 0 !important;
}
@media screen and (max-width: 768px) {
  .container-fluid{
    padding-left:0;
    padding-right: 0;
  }
  
}