span{
    margin-left: 5px;
    margin-right: 5px;
}
span:hover{
    cursor: pointer;
}
.popover{
    color: #ffffff;
    background-color: #000000;
}
.popover-body{
    color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F4F7F8;
  }
  