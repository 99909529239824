.text-green{
  color: var(--color-green);
}

.text-gray{
  color: var(--color-gray);
}

.text-white{
  color: var(--color-white);
}

.text-black{
  color: var(--color-black);
}

.bg-green{
  background-color: var(--color-green);
}

.bg-green-light{
  background-color: var(--color-green-light);
}

.bg-black-green{
  background-color: var(--color-black-green);
}

.offcanvas {
  --bs-offcanvas-width: 450px;
}
.container-fluid{
  padding-left: 20px !important;
  padding-right: 20px !important;
}

*{
  --color-green: #43b12e;
  --color-green-light: #C8D300;
  --color-dark-green: #35783c;
  --color-black-green: #052200;
  --color-white: #fff;
  --color-gray: #555759;
  --color-black: #000;
}