.nav-tabs {
    --bs-nav-tabs-border-width: 0;
    --bs-nav-tabs-border-color: 0;
    --bs-nav-tabs-border-radius: 0;
    --bs-nav-tabs-link-hover-border-color: #73c463;
    --bs-nav-tabs-link-active-color: transparent;
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
    /* --border-bottom: solid #2c751e; */
}
.nav-link{
  border: 0;
  color: #000000 !important;
}
.nav-item .active {
  border: 0;
  border-bottom: #43b12e 2px solid !important;
  background-color: transparent !important;
  font-weight: bold;
}