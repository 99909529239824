.table{
    --bs-table-hover-bg: #ffffff00;
}

.table-hover tbody tr:hover {
    border: 1px solid #43b12e;
    border-radius: 5px;
}
tbody, td, tfoot, th{
    border-style: none !important;
}
.table-hover .chevron tbody tr {
    color: #555759;
}
td{
    color: #555759 !important;
}
.table-hover  tbody tr:hover .chevron{
border-radius: 10px;
    color: #43b12e;
}
.table-hover  tbody td:hover {
    color: #000000 !important;
    }
